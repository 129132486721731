import Keycloak from "keycloak-js";

export const elName = "p-gdpr-info";

const template = document.createElement("template");
template.innerHTML = `
<link href="${(window as any)["common/generated/common-bundle.css.ftl"]}" rel="stylesheet"/>
<style>
:host {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

:host h2 {
    font-family: var(--font-stack-Open-Sans);
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    color: var(--gray-midnight);
}

:host .button {
    align-self: flex-end;
    margin: 0;
}

:host * {
    margin: 0;
}
</style>
<h2>
  <slot name="headline-text"></slot>
</h2>
<p class="initial-text">
  <slot name="initial-text"></slot>
</p>
<p class="dl-available-text" hidden>
  <slot name="dl-available-text"></slot>
</p>
<!-- TODO p visibility -->
<div class="alert" hidden>
</div>
<button class="button button-secondary button-size-m icon icon-folder">
    <slot name="request-text"></slot>
</button>
<!-- TODO download attr could also be zip -->
<a class="button button-primary button-size-m icon icon-download" hidden>
    <slot name="download-text"></slot>
</a>
`;

/**
 * GdprInfo manages the visibility of various elements based on the user's GDPR status.
 * This could and should easily be a ftl component with 100% server-side rendering. But Keycloaks `AccountConsole` does
 * not pass the user to the template processor.
 */
export class GdprInfo extends HTMLElement {
  private button: HTMLButtonElement;
  private anchor: HTMLAnchorElement;
  private alert: HTMLDivElement;
  private initialText: HTMLParagraphElement;
  private dlAvailableText: HTMLParagraphElement;
  private _keycloak: Keycloak;
  private _userInfo: UserInfo;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this.classList.add(elName);
    this.button = this.shadowRoot.querySelector("button");
    this.anchor = this.shadowRoot.querySelector("a");
    this.alert = this.shadowRoot.querySelector(".alert");
    this.initialText = this.shadowRoot.querySelector(".initial-text");
    this.dlAvailableText = this.shadowRoot.querySelector(".dl-available-text");
    const downloadUrl = this.dataset.downloadurl;
    const downloadFileName = this.dataset.downloadfilename;
    const requiredActionName = this.dataset.requiredactionname;
    this.anchor.href = downloadUrl;
    this.anchor.download = downloadFileName;

    this.button.addEventListener("click", () => {
      this._keycloak
        .login({
          action: requiredActionName,
        })
        .catch((err) => {
          this.showAlert(errorText);
        });
    });
  }

  set keycloak(kc: Keycloak) {
    this._keycloak = kc;
  }

  set userInfo(info: UserInfo) {
    this._userInfo = info;
    this.updateUI();
  }

  private updateUI() {
    // only allow triggering the request 3 minutes after email confirmation (give Atlas some time to receive messages)
    const doiReceivedOn = this._userInfo && this._userInfo.doiReceivedOn;
    const doiYoungerThan5Min =
      doiReceivedOn && doiReceivedOn.getTime() + 3 * minuteInMs > Date.now();
    if (doiYoungerThan5Min) {
      this.showInfo(registrationCooldownText);
      this.button.hidden = true;
      return;
    }

    const gdprInfo = this._userInfo?.gdprStatus;
    // allow requesting info if no request in progress
    if (!gdprInfo) {
      this.initialText.hidden = false;
      this.dlAvailableText.hidden = true;
      this.button.hidden = false;
      this.anchor.hidden = true;
      this.hideAlert();
      return;
    }

    // for pending requests we check the until date and show an error if the request should be done or no until is given
    if (gdprInfo.state === "PENDING") {
      if (gdprInfo.until && gdprInfo.until.getTime() > Date.now()) {
        this.showInfo(requestInProgressText);
        this.button.hidden = true;
        this.anchor.hidden = true;
        return;
      }
      // pending without until or elapsed until should not happen
      this.showAlert(errorText);
      this.button.hidden = false;
      this.anchor.hidden = true;
      return;
    }

    if (gdprInfo.state === "FAILED") {
      this.showAlert(errorText);
      this.button.hidden = false;
      this.anchor.hidden = true;
      return;
    }

    if (gdprInfo.state === "SUCCEEDED") {
      if (gdprInfo.until && gdprInfo.until.getTime() > Date.now()) {
        this.initialText.hidden = true;
        this.dlAvailableText.hidden = false;
        this.button.hidden = true;
        this.anchor.hidden = false;
        this.hideAlert();
        return;
      } else if (gdprInfo.until && gdprInfo.until.getTime() < Date.now()) {
        return;
      }
      // succeeded without until should not happen
      this.showAlert(errorText);
      this.button.hidden = false;
      this.anchor.hidden = true;
      return;
    }
  }

  private showAlert(message: string) {
    this.alert.classList.toggle("alert__info", false);
    this.alert.classList.toggle("alert__error", true);
    this.alert.role = "alert";
    this.alert.innerText = message;
    this.alert.hidden = false;
  }

  private showInfo(message: string) {
    this.alert.classList.toggle("alert__info", true);
    this.alert.classList.toggle("alert__error", false);
    this.alert.role = "";
    this.alert.innerText = message;
    this.alert.hidden = false;
  }

  private hideAlert() {
    this.alert.hidden = true;
  }
}

export type GdprRequestState = "PENDING" | "SUCCEEDED" | "FAILED";

export interface GdprStatus {
  state: GdprRequestState;
  until?: Date;
}

export interface UserInfo {
  doiReceivedOn?: Date;
  gdprStatus?: GdprStatus;
}

const minuteInMs = 60 * 1000;

export const errorText =
  "Leider können wir deine Anfrage zur Zeit nicht bearbeiten. Wir arbeiten bereits an einer Lösung.";
export const registrationCooldownText =
  "Die Funktion steht dir in 2-3 Minuten zur Verfügung. Schau einfach gleich noch einmal vorbei.";
export const requestInProgressText =
  "Deine Daten werden innerhalb der nächsten 48 Stunden bereitgestellt. Du erhältst eine Mitteilung in der Penny App.";

export function gdprStatusParser(key: string, value): any {
  if (key === "until") {
    if (!value) {
      return undefined;
    }
    return new Date(value);
  }
  return value;
}

customElements.define(elName, GdprInfo);
